import { parse } from "vue-currency-input";
import formatThings from "@/mixins/general/formatThings";

export default {
  mixins: [formatThings],
  data() {
    return {
      columns: [
        {
          title: "#",
          dataIndex: "id",
          key: "id",
          sorter: (a, b) => a.id - b.id,
          width: 80,
        },
        {
          title: "Contratante",
          scopedSlots: { customRender: "customer" },
          width: 180,
        },
        {
          title: "Produto",
          scopedSlots: { customRender: "product" },
          width: 180,
        },
        {
          title: "Empresa",
          scopedSlots: { customRender: "company" },
          width: 130,
        },
        {
          title: "Filial",
          scopedSlots: { customRender: "company_branch" },
          width: 150,
        },
        {
          title: "Fornecedor",
          scopedSlots: { customRender: "product_supplier" },
          width: 180,
        },
        {
          title: "Valor Net",
          scopedSlots: { customRender: "net_value" },
          width: 130,
          sorter: (a, b) => this.tableSorter("net_value", a, b),
        },
        {
          title: "Check-in",
          scopedSlots: { customRender: "checkin" },
          width: 130,
          sorter: (a, b) => this.tableSorter("checkin", a, b),
        },
        {
          title: "",
          scopedSlots: { customRender: "user" },
          width: 80,
        },
        {
          title: "Data venda",
          scopedSlots: { customRender: "release_date" },
          width: 130,
        },
        {
          title: "",
          key: "action",
          align: "right",
          fixed: "right",
          width: 80,
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  mounted() {},
  methods: {
    tableSorter(col, a, b) {
      if (col === "net_value") return a.net_value - b.net_value;
      if (col === "checkin")
        return new Date(a.checkin).getTime() - new Date(b.checkin).getTime();
    },
    newContractList(contracstList) {
      let newList = [];

      if (contracstList !== undefined)
        contracstList.forEach((contract) => {
          if (["Serviços", "Reserva Online"].includes(contract.package_type)) {
            JSON.parse(contract.contracted_services).forEach((product) => {
              if (product === "hotel")
                JSON.parse(contract.meta.hotel_rows).forEach((hotel) => {
                  let theHotel = this.hotelEntries(contract, hotel);

                  if (
                    this.contract.filters.productSupplier ||
                    this.contract.filters.checkin.selected.length ||
                    this.contract.filters.auditStatus.selected.length ||
                    this.contract.filters.productsCategory.selected.length
                  ) {
                    let checkinRangeStart = new Date(
                      this.contract.filters.checkin.selected[0] +
                        "T00:00:00.992Z"
                    ).getTime();
                    let checkinRangeEnd = new Date(
                      this.contract.filters.checkin.selected[1] +
                        "T23:50:00.992Z"
                    ).getTime();
                    let theCheckin = new Date(theHotel.checkin).getTime();

                    if (
                      theHotel.product_supplier.includes(
                        this.contract.filters.productSupplier
                      ) ||
                      (theCheckin > checkinRangeStart &&
                        theCheckin < checkinRangeEnd) ||
                      this.contract.filters.auditStatus.selected.includes(
                        theHotel.audit_status
                      ) ||
                      this.contract.filters.productsCategory.selected.includes(
                        theHotel.product_type
                      )
                    )
                      newList.push(theHotel);
                  } else {
                    newList.push(theHotel);
                  }
                });

              if (product === "flight")
                JSON.parse(contract.meta.flight_sections_rows).forEach(
                  (flight) => {
                    if (
                      contract.meta[`flight_${flight.id}_type`] === "Regular"
                    ) {
                      let theFlight = this.flightEntries(contract, flight);

                      if (
                        this.contract.filters.productSupplier ||
                        this.contract.filters.checkin.selected.length ||
                        this.contract.filters.auditStatus.selected.length ||
                        this.contract.filters.productsCategory.selected.length
                      ) {
                        let checkinRangeStart = new Date(
                          this.contract.filters.checkin.selected[0] +
                            "T00:00:00.992Z"
                        ).getTime();
                        let checkinRangeEnd = new Date(
                          this.contract.filters.checkin.selected[1] +
                            "T23:50:00.992Z"
                        ).getTime();
                        let theCheckin = new Date(theFlight.checkin).getTime();

                        if (
                          theFlight.product_supplier.includes(
                            this.contract.filters.productSupplier
                          ) ||
                          (theCheckin > checkinRangeStart &&
                            theCheckin < checkinRangeEnd) ||
                          this.contract.filters.auditStatus.selected.includes(
                            theFlight.audit_status
                          ) ||
                          this.contract.filters.productsCategory.selected.includes(
                            theFlight.product_type
                          )
                        )
                          newList.push(theFlight);
                      } else {
                        newList.push(theFlight);
                      }
                    }
                  }
                );

              if (product === "service")
                JSON.parse(contract.meta.service_rows).forEach((service) => {
                  let theService = this.serviceEntries(contract, service);

                  if (
                    this.contract.filters.productSupplier ||
                    this.contract.filters.checkin.selected.length ||
                    this.contract.filters.auditStatus.selected.length ||
                    this.contract.filters.productsCategory.selected.length
                  ) {
                    let checkinRangeStart = new Date(
                      this.contract.filters.checkin.selected[0] +
                        "T00:00:00.992Z"
                    ).getTime();
                    let checkinRangeEnd = new Date(
                      this.contract.filters.checkin.selected[1] +
                        "T23:50:00.992Z"
                    ).getTime();
                    let theCheckin = new Date(theService.checkin).getTime();

                    if (
                      theService.product_supplier.includes(
                        this.contract.filters.productSupplier
                      ) ||
                      (theCheckin > checkinRangeStart &&
                        theCheckin < checkinRangeEnd) ||
                      this.contract.filters.auditStatus.selected.includes(
                        theService.audit_status
                      ) ||
                      this.contract.filters.productsCategory.selected.includes(
                        theService.product_type
                      )
                    )
                      newList.push(theService);
                  } else {
                    newList.push(theService);
                  }
                });
            });
          } else {
            let theCheckin = "";

            JSON.parse(contract.contracted_services).forEach((product) => {
              if (product === "hotel")
                theCheckin = contract.meta[`hotel_1_checkin`];
              if (product === "flight")
                theCheckin = contract.meta[`flight_1_section_1_departure_date`];
              if (product === "service")
                theCheckin = contract.meta[`service_1_checkin`];
            });

            let thePackage = {
              id: contract.id,
              customer: contract.customer,

              company:
                contract.meta[`contract_finances_package_company`] ??
                contract.company_details.trading_name,
              company_branch:
                contract.meta[`contract_finances_package_company_branch`] ??
                contract.company_branch.name,
              product: "Pacote Completo",
              product_supplier: `${
                contract.meta[`contract_finances_package_supplier_id`]
              } - ${contract.meta[`contract_finances_package_supplier`]}`,
              net_value: parse(
                contract.meta[`contract_finances_package_net_value`]
              ),
              checkin: theCheckin,
              user: contract.user,
              release_date: contract.release_date,
            };

            if (
              this.contract.filters.productSupplier ||
              this.contract.filters.checkin.selected.length
            ) {
              let checkinRangeStart = new Date(
                this.contract.filters.checkin.selected[0]
              ).getTime();
              let checkinRangeEnd = new Date(
                this.contract.filters.checkin.selected[1]
              ).getTime();
              let theCheckin = new Date(thePackage.checkin).getTime();

              if (
                thePackage.product_supplier.includes(
                  this.contract.filters.productSupplier
                ) ||
                (theCheckin > checkinRangeStart && theCheckin < checkinRangeEnd)
              )
                newList.push(thePackage);
            } else {
              newList.push(thePackage);
            }
          }
        });

      return newList;
    },
    hotelEntries(contract, hotel) {
      const entry = {
        id: contract.id,
        customer: contract.customer,
        product: contract.meta[`hotel_${hotel.id}_name`],
        product_type: "hotel",
        company:
          contract.meta[`contract_finances_hotel_${hotel.id}_company`] ??
          contract.company_details.trading_name,
        company_branch:
          contract.meta[`contract_finances_hotel_${hotel.id}_company_branch`] ??
          contract.company_branch.name,
        product_supplier: `${
          contract.meta[`contract_finances_hotel_${hotel.id}_supplier_id`]
        } - ${contract.meta[`contract_finances_hotel_${hotel.id}_supplier`]}`,
        net_value: parse(
          contract.meta[`contract_finances_hotel_${hotel.id}_net_value`]
        ),
        checkin: contract.meta[`hotel_${hotel.id}_checkin`],
        user: contract.user,
        release_date: contract.release_date,
        audit_status:
          contract.meta[`contract_finances_hotel_${hotel.id}_audit_status`] ??
          "Em aberto",
      };

      return entry;
    },
    flightEntries(contract, flight) {
      let airports = "";
      let theContract = {};
      let totalNet = 0;

      flight.sections.forEach((section) => {
        airports +=
          contract.meta[`flight_${flight.id}_section_${section.id}_origin`] +
          " ✈ " +
          contract.meta[
            `flight_${flight.id}_section_${section.id}_destination`
          ] +
          " / ";
      });

      flight.travellers.forEach((traveller) => {
        totalNet +=
          parse(
            contract.meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
          ) +
          parse(
            contract.meta[`flight_${flight.id}_traveller_${traveller.id}_value`]
          );
      });

      theContract = {
        id: contract.id,
        customer: contract.customer,
        product: airports,
        product_type: "flight",
        company: contract.company_details.trading_name,
        company_branch: contract.company_branch.name,
        product_supplier: `${
          contract.meta[`contract_finances_flight_${flight.id}_supplier_id`]
        } - ${contract.meta[`contract_finances_flight_${flight.id}_supplier`]}`,
        net_value: totalNet,
        checkin: contract.meta[`flight_${flight.id}_section_1_departure_date`],
        user: contract.user,
        release_date: contract.release_date,
        audit_status:
          contract.meta[`contract_finances_flight_${flight.id}_audit_status`] ??
          "Em aberto",
      };

      return theContract;
    },
    serviceEntries(contract, service) {
      return {
        id: contract.id,
        customer: contract.customer,
        product: `${contract.meta[`service_${service.id}_type`]} - ${
          contract.meta[`service_${service.id}_name`]
        }`,
        product_type: "service",
        company:
          contract.meta[`contract_finances_service_${service.id}_company`] ??
          contract.company_details.trading_name,
        company_branch:
          contract.meta[
            `contract_finances_service_${service.id}_company_branch`
          ] ?? contract.company_branch.name,
        product_supplier: `${
          contract.meta[`contract_finances_service_${service.id}_supplier_id`]
        } - ${
          contract.meta[`contract_finances_service_${service.id}_supplier`]
        }`,
        net_value: parse(
          contract.meta[`contract_finances_service_${service.id}_net_value`]
        ),
        checkin: contract.meta[`service_${service.id}_checkin`],
        user: contract.user,
        release_date: contract.release_date,
        audit_status:
          contract.meta[
            `contract_finances_service_${service.id}_audit_status`
          ] ?? "Em aberto",
      };
    },
  },
};
