<template>
  <section class="pd-20">
    <aPageHeader class="pd-0">
      <template #title>
        <h1>
          <img src="@/assets/images/agreement.png" alt="ico" />
          Relatório a Pagar (Embarque)
        </h1>
      </template>

      <template #extra>
        <span class="total-net"
          >Valor Total: <strong> {{ sumAllNetValues() }}</strong></span
        >
        <downloadExcel
          v-if="$root.isAdmin()"
          class="btn btn-default"
          :header="excelFile.header"
          :name="excelFile.fileName"
          :data="newContractList(contract.list)"
          :fields="excelFile.collumns"
          :footer="excelFile.footer"
          style="display: inline-block"
        >
          <img class="c-pointer" src="@/assets/images/dashboard/excel.png" alt="excel" />
        </downloadExcel>
      </template>
    </aPageHeader>

    <ContractDeparturesToPayFiltersCollapse
      :filters="contract.filters"
      :loading="contract.loading"
      @onClickFilterContracts="onClickFilterContracts"
    />

    <aTable
      class="travel-table"
      :columns="columns"
      :data-source="newContractList(contract.list)"
      :loading="contract.loading"
      :pagination="{
        pageSize: 30,
        showSizeChanger: true,
        pageSizeOptions: pageSizeOptions,
      }"
      :scroll="{ x: 1300 }"
    >
      <div slot="customer" slot-scope="record">
        <div class="dotted-phrase upper">{{ theContractor(record) }}</div>
      </div>

      <div slot="product" slot-scope="record">
        <div class="dotted-phrase upper">
          <a-tooltip :title="record.product">
            {{ record.product }}
          </a-tooltip>
        </div>
      </div>

      <div slot="company" slot-scope="record">
        {{ record.company }}
      </div>

      <div slot="company_branch" slot-scope="record">
        {{ record.company_branch }}
      </div>

      <div slot="product_supplier" slot-scope="record">
        <div class="dotted-phrase upper">
          <a-tooltip :title="record.product_supplier">
            {{ record.product_supplier }}
          </a-tooltip>
        </div>
      </div>

      <div slot="net_value" slot-scope="record">
        {{ record.net_value | formatPricePtBr }}
      </div>

      <div slot="checkin" slot-scope="record">
        {{ record.checkin | formatMultiDates }}
      </div>

      <div slot="user" slot-scope="record">
        <a-tooltip>
          <template slot="title">
            {{ record.user.first_name }} {{ record.user.last_name }}
          </template>
          <span id="avatar">
            <a-avatar
              v-if="record.user.avatar"
              :src="record.user.avatar"
              :size="30"
            />
            <a-avatar
              v-else
              class="upper"
              style="color: #f56a00; background-color: #fde3cf"
            >
              {{ record.user.first_name.substring(0, 1)
              }}{{ record.user.last_name.substring(0, 1) }}
            </a-avatar>
          </span>
        </a-tooltip>
      </div>

      <div slot="release_date" slot-scope="record">
        {{ record.release_date | formatDate }}
      </div>

      <div class="travel-actions" slot="action" slot-scope="record">
        <a-tooltip placement="top" title="Ver contrato">
          <a
            class="relative"
            v-on:click.ctrl="viewContractDetails(record.id)"
            v-on:click.exact="viewContractDetails(record.id)"
            style="top: -4px"
          >
            <img
              src="@/assets/images/dashboard/contracts/details-ico.png"
              width="19" alt="img"
            />
          </a>
        </a-tooltip>
      </div>
    </aTable>

    <a-modal
      class="contract-details"
      width="884px"
      v-model="openContractModal"
      :title="false"
      :footer="false"
      @cancel="openContractModal = false"
    >
      <ContractModal v-if="openContractModal" :contractId="contractId" />
    </a-modal>
  </section>
</template>

<script>
import { format } from "date-fns";
import formatThings from "@/mixins/general/formatThings";
import contractMixins from "@/components/contracts/mixins/contractMixins";
import ContractDeparturesToPayMixins from "@/components/contracts/mixins/ContractDeparturesToPayMixins";
import ContractModal from "@/components/contracts/modals/ContractModal.vue";
import ContractDeparturesToPayFiltersCollapse from "@/components/contracts/collapses/ContractDeparturesToPayFiltersCollapse.vue";

export default {
  name: "ContractDeparturesToPay",
  mixins: [formatThings, contractMixins, ContractDeparturesToPayMixins],
  components: { ContractModal, ContractDeparturesToPayFiltersCollapse },
  data() {
    return {
      contractId: "",
      openContractModal: false,
      pageSizeOptions: [
        "10",
        "20",
        "25",
        "30",
        "50",
        "100",
        "200",
        "500",
        "1000",
        "9999",
      ],
      excelFile: {
        header: "Relatório a Pagar (Embarque)",
        fileName: "Relatório a Pagar (Embarque).xls",
        collumns: {
          ID: "id",
          Contrante: {
            callback: (contract) => {
              return this.theContractor(contract);
            },
          },
          Produto: "product",
          Empresa: {
            callback: (contract) => {
              return `${contract.company.trading_name}`;
            },
          },
          Filial: {
            callback: (contract) => {
              return `${contract.company_branch.name}`;
            },
          },
          Fornecedor: {
            callback: (contract) => {
              return `${contract.product_supplier}`;
            },
          },
          "Valor net": {
            callback: (contract) => {
              return `${this.formatValueToExcel(contract.net_value)}`;
            },
          },
          "Check-in": {
            callback: (contract) => {
              return `${this.formatMultiDates(contract.checkin)}`;
            },
          },
          Usuario: {
            callback: (contract) => {
              return `${contract.user.first_name} ${contract.user.last_name}`;
            },
          },
          "Data venda": {
            callback: (contract) => {
              return `${contract.release_date}`;
            },
          },
        },
        data: [],
        footer: [],
      },
    };
  },
  mounted() {
    let date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth(),
      firstDay = new Date(y, m, 1),
      lastDay = new Date(y, m + 1, 0);
    firstDay = format(firstDay, "yyyy-MM-dd");
    lastDay = format(lastDay, "yyyy-MM-dd");
    this.contract.filters.status.selected = "concluded";
    this.contract.pagination.perPage = 9999;
    this.contract.filters.orderBy = "created";
    this.contract.filters.order = "desc";
    this.contract.filters.period.selected = [`${firstDay}`, `${lastDay}`];

    this.contractUrlDefaults();
    this.getContracts();
  },
  methods: {
    sumAllNetValues() {
      let totalNet = 0;

      this.newContractList(this.contract.list).forEach((contract) => {
        totalNet += contract.net_value;
      });

      return this.formatPricePtBr(totalNet);
    },
    onClickFilterContracts(filters) {
      this.contract.filters = filters;
      this.getContracts();
    },
    viewContractDetails(id) {
      this.contractId = id;
      this.openContractModal = true;
    },
    theContractor(contract) {
      let theContractor = "";

      if (contract.customer.person_type === "Pessoa Física")
        theContractor = `${contract.customer.id} - ${contract.customer.first_name} ${contract.customer.last_name}`;

      if (contract.customer.person_type === "Pessoa Jurídica")
        theContractor = `${contract.customer.id} - ${contract.customer.trading_name}`;

      return theContractor;
    },
  },
};
</script>

<style lang="sass">
.total-net
  strong
    font-size: 18px
.contract-details
  .ant-modal-close
    top: -48px
    padding: 0 !important
    height: 20px
    width: 20px
    color: #FFF !important
    right: 16px
  .ant-modal-body
    padding: 0
</style>
